import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from '../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { MatDialog } from '@angular/material/dialog';
import { BasicPopupComponent } from '../shared/popups/basic-popup/basic-popup.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-site-visit',
  templateUrl: './site-visit.component.html',
  styleUrls: ['./site-visit.component.css']
})
export class SiteVisitComponent implements OnInit {
  [x: string]: any;
  public lat;
  public lng;
  zoom;
  siteVisitForm: FormGroup;
  formSubmitted = false;
  siteVisitButtonLabel: string = '';
  site_visit_id: string;
  is_instruction: boolean = false;
  is_cannabis_business: any;
  version_no: '';
  isV2CaptchaRequired: boolean = false;
  siteKey: string = environment.reCaptcha2SiteKey

  
  constructor(
    public _api: ApiService,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private cookieService: CookieService,
    private dialog: MatDialog,
    private renderer: Renderer2
  ) { 
    this.siteVisitForm = this.formBuilder.group({
      fin_name :  new FormControl({value: '', disabled: true}),
      am_business_name :  new FormControl({value: '', disabled: true}),
      external_user_name :  new FormControl({value: '', disabled: true}),
      inspection_purpose :  new FormControl('' , [Validators.required ]),
      inspection_purpose_other : new FormControl('' , [Validators.required ]),
      is_inspection :  new FormControl('' , [Validators.required ]),
      inspection_title :  new FormControl({value: '', disabled: true}),
      inspection_instruction :  new FormControl({value: '', disabled: true}),
      verify_business_name :  new FormControl({value: '', disabled: true}),
      business_dba :  new FormControl({value: '', disabled: true}),
      audit_location_physical_address :  new FormControl({value: '', disabled: true}),
      business_corporate_address :  new FormControl({value: '', disabled: true}),
      business_phone_number :  new FormControl({value: '', disabled: true}),
      service_provider :  new FormControl({value: '', disabled: true}),
      is_edit :  new FormControl(''),
    });
  }
  
checkLocationAccess() {
  if (!navigator.geolocation) {
    alert("Geolocation is not supported by your browser.");
    return;
  }

  navigator.geolocation.getCurrentPosition(
    (position) => {
      
      console.log("Location access granted.");
      console.log(`Latitude: ${position.coords.latitude}, Longitude: ${position.coords.longitude}`);
      this.lat = position.coords.latitude;
      this.lng = position.coords.longitude;
      this.zoom = 16;
      this.cookieService.set('latitude', this.lat);
      this.cookieService.set('longitude', this.lng);
      this.getHeaderFooter();
      // this.getSiteVisitDetails();
    },
    (error) => {
      switch (error.code) {
        case 1: // PERMISSION_DENIED
          this.showPopup(
            "Location Permission Denied",
            "Please enable location access in your browser settings.",
            "PERMISSION_DENIED"
          );
          break;
        case 2: // POSITION_UNAVAILABLE
        this.showPopup(
            "Location Unavailable",
            "We could not retrieve your location. Please ensure location services are enabled on your device.",
            "POSITION_UNAVAILABLE"
          );
          break;
        case 3: // TIMEOUT
        this.showPopup(
            "Request Timed Out",
            "The request to retrieve your location timed out. Please try again.",
            "TIMEOUT"
          );
          break;
        default:
          this.showPopup(
            "Error",
            "An unknown error occurred while accessing your location.",
            "Error"
          );
      }
    }
  );
}

showPopup(title: string, message: string, case_type: string) {
  const dialogRef = this.dialog.open(BasicPopupComponent, {
    maxWidth: '600px'
    , data: {
      pageTitle: title,
      message: message,
      pageType: case_type
    }
  });

  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      
    }
  });
}

isMobileBrowser(): boolean {
  const userAgent = navigator.userAgent || navigator.vendor;
  return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent.toLowerCase());
}

ngOnInit(): void {


  if (this.isMobileBrowser()) {
    console.log("Mobile browser detected");
  } else {
    console.log("Desktop browser detected");
  }

  this.route.paramMap.subscribe(params => {
    this.site_visit_id = params.get('id');
    this.cookieService.set('site_visit_id', this.site_visit_id);
    this.checkLocationAccess();
  });
  this.siteVisitForm.get('is_edit')?.valueChanges.subscribe((value) => {
    if (value === 'yes') {
      this.addValidation();
    } else {
      this.clearValidation();
    }
    this.updateValidationValue();
  });

  const isMobile = this.isMobileBrowser();
  const observer = new MutationObserver(() => {
    const inputElements = document.querySelectorAll('input');
    inputElements.forEach((input: HTMLElement) => {
      if (isMobile) {
        this.renderer.addClass(input, 'mobile-input');
        this.renderer.removeClass(input, 'desktop-input');
      } else {
        this.renderer.addClass(input, 'desktop-input');
        this.renderer.removeClass(input, 'mobile-input');
      }
    });
  });

  observer.observe(document.body, { childList: true, subtree: true });
}

getHeaderFooter(){
  const apiData = {
    site_visit_id: this.site_visit_id,
    latitude: this.cookieService.get('latitude'),
    longitude:  this.cookieService.get('longitude')
  };
  this.spinner.show();
  this._api.functionPOST('web/siteVisit/getHeaderFooter', apiData).subscribe((response) => {
    const logoURL = response['data']['static_base_url']+'/'+response['data']['web_hf']['logo_path']+`?timestamp=${new Date().getTime()}`;
    const faviconURL = response['data']['static_base_url']+'/'+response['data']['web_hf']['favicon_path']+`?timestamp=${new Date().getTime()}`;
    const headerContent = response['data']['web_hf']['header'];
    const footerContent = response['data']['web_hf']['footer'];
    const businessURL = response['data']['web_hf']['provider_web_url'];
    const highLightColor = response['data']['web_hf']['highlight_color'];
    document.documentElement.style.setProperty('--dynamic-bg-color', highLightColor);
    document.documentElement.style.setProperty('--dynamic-section-bg-color', highLightColor);

    this.setFavicon(faviconURL);
    this.setLogo(logoURL);
    this.setHeader(headerContent);
    this.setFooter(footerContent);
    this.setProviderWebURL(businessURL);
    this.getSiteVisitDetails();
  });
  this.spinner.hide();
}

setLogo(url: string): void {
  const imgElement = document.getElementById('dynamicLogo') as HTMLImageElement;
  if (imgElement) {
      imgElement.src = url;
      imgElement.alt = 'Logo';
  }
}

setFavicon(url: string): void {
  const favicon = document.getElementById('dynamicFavicon') as HTMLLinkElement;
  if (favicon) {
    favicon.href = url;
  }
}

setHeader(header: string): void {
  const headerTitle = document.getElementById('dynamicHeader');
  if (headerTitle) {
    headerTitle.innerHTML = header;
  }
}

setFooter(footer: string): void {
  const footerTitle = document.getElementById('dynamicFooter');
  if (footerTitle) {
    footerTitle.innerHTML = footer;
  }
}

setProviderWebURL(web_url: string): void {
  const webURL = document.getElementById('providerWebURL') as HTMLLinkElement;;
  if (webURL) {
    webURL.href = web_url;
  }
}

  getSiteVisitDetails(v2token?){
    let isV2Required;
    if(v2token){
      isV2Required = true;
    }
    const apiData = {
      site_visit_id: this.site_visit_id,
      latitude: this.cookieService.get('latitude'),
      longitude:  this.cookieService.get('longitude'),
      isV2CaptchaRequired: isV2Required
    };
    this.spinner.show();
    this._api.functionPOST('web/getSiteVisit', apiData, v2token).subscribe((response) => {
      if(response['success'] && !response['data']['isV2CaptchaRequired']){
        this.isV2CaptchaRequired = false;
        this.version_no = response['data']['df_template']['version_no'];
        this.cookieService.set('token', response['data']['token']);
        if (response['data']['instructions'] === '' || response['data']['instructions'] === null){
          this.is_instruction = false;
        }else{
          this.is_instruction = true;
        }
        this.is_cannabis_business = response['data']['is_cannabis_inspection'];
        if (response['data']['is_cannabis_inspection'] === 'Y'){
          this.siteVisitButtonLabel = 'Start Cannabis Business Inspection Now';
        }else{
          this.siteVisitButtonLabel = 'Start Inspection Now';
        }

        this.siteVisitForm.patchValue({
          fin_name: response['data']['sp_bp_business_name'],
          am_business_name: response['data']['bp_business_name'],
          external_user_name: response['data']['um_user_sitevisit_fullname'],
          inspection_title: response['data']['visit_purpose'],
          inspection_instruction: response['data']['instructions'],
          verify_business_name: response['data']['v_bp_business']['name'],
          business_dba: response['data']['v_bp_business']['dba'],
          audit_location_physical_address: response['data']['premise_address'],
          business_corporate_address: response['data']['v_bp_business']['legal_address'],
          business_phone_number: response['data']['bp_business_phone']['phone'],
          service_provider: response['data']['sp_bp_business_name'],
          is_edit: 'no'
        });
      } else if(response['data']['isV2CaptchaRequired']) {
        this.isV2CaptchaRequired = response['data']['isV2CaptchaRequired'];
      }else {
        const dialogRef = this.dialog.open(BasicPopupComponent, {
          maxWidth: '600px'
          , data: {
            pageTitle: 'Site Visit Status',
            message: response['error'],
            pageType: 'Warning',
          }
        });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
            }
          });
      }
      
      this.spinner.hide();
      });
  }

  addValidation(){
    this.siteVisitForm.get('verify_business_name')?.setValidators(Validators.required);
    this.siteVisitForm.get('verify_business_name')?.enable();

    // this.siteVisitForm.get('business_dba')?.setValidators(Validators.required);
    this.siteVisitForm.get('business_dba')?.enable();

    this.siteVisitForm.get('audit_location_physical_address')?.setValidators(Validators.required);
    this.siteVisitForm.get('audit_location_physical_address')?.enable();

    this.siteVisitForm.get('business_corporate_address')?.setValidators(Validators.required);
    this.siteVisitForm.get('business_corporate_address')?.enable();

    this.siteVisitForm.get('business_phone_number')?.setValidators(Validators.required);
    this.siteVisitForm.get('business_phone_number')?.enable();

    // this.siteVisitForm.get('service_provider')?.setValidators(Validators.required);
    // this.siteVisitForm.get('service_provider')?.enable();

  }

  clearValidation(){
    this.siteVisitForm.get('verify_business_name')?.clearValidators();
    this.siteVisitForm.get('verify_business_name')?.disable();

    // this.siteVisitForm.get('business_dba')?.clearValidators();
    this.siteVisitForm.get('business_dba')?.disable();

    this.siteVisitForm.get('audit_location_physical_address')?.clearValidators();
    this.siteVisitForm.get('audit_location_physical_address')?.disable();

    this.siteVisitForm.get('business_corporate_address')?.clearValidators();
    this.siteVisitForm.get('business_corporate_address')?.disable();

    this.siteVisitForm.get('business_phone_number')?.clearValidators();
    this.siteVisitForm.get('business_phone_number')?.disable();

    // this.siteVisitForm.get('service_provider')?.clearValidators();
    // this.siteVisitForm.get('service_provider')?.disable();
  }

  updateValidationValue(){
    this.siteVisitForm.get('verify_business_name')?.updateValueAndValidity();
    this.siteVisitForm.get('business_dba')?.updateValueAndValidity();
    this.siteVisitForm.get('audit_location_physical_address')?.updateValueAndValidity();
    this.siteVisitForm.get('business_corporate_address')?.updateValueAndValidity();
    this.siteVisitForm.get('business_phone_number')?.updateValueAndValidity();
    // this.siteVisitForm.get('service_provider')?.updateValueAndValidity();
  }

  updateSiteAddress(){
    console.log('-------------------------', this.siteVisitForm.get('verify_business_name').value);
  }

  cancel(){
    this.clearValidation();
    this.updateValidationValue();
    this.siteVisitForm.patchValue({
      is_edit: 'no'
    })
  }

  goToSiteInspection(){
    let business_type;
    let business_data: {};

    if(this.is_cannabis_business === 'Y'){
      business_type = 'cannabis';
    }else{
      business_type = 'non_cannabis';
    }

    if(this.siteVisitForm.get('is_edit').value === 'yes'){
      let verify_business_name = this.siteVisitForm.get('verify_business_name')?.value;
      let business_dba = this.siteVisitForm.get('business_dba')?.value;
      let audit_location_physical_address = this.siteVisitForm.get('audit_location_physical_address')?.value;
      let business_corporate_address = this.siteVisitForm.get('business_corporate_address')?.value;
      let business_phone_number = this.siteVisitForm.get('business_phone_number')?.value;
      let service_provider = this.siteVisitForm.get('service_provider')?.value;
  
      if(!verify_business_name){
        this._api.openSnackBar('Verify Business Name', 'Error');
        return;
      }
      // if(!business_dba){
      //   this._api.openSnackBar('Enter Business DBA', 'Error');
      //   return;
      // }
      if(!audit_location_physical_address){
        this._api.openSnackBar("Enter Audit Location Physical Address", 'Error');
        return;
      }
      if(!business_corporate_address){
        this._api.openSnackBar("Enter Business Corporate Address", 'Error');
        return;
      }
      if(!business_phone_number){
        this._api.openSnackBar("Enter Business Phone Number", 'Error');
        return;
      }
      if(!service_provider){
        this._api.openSnackBar("Enter Service Provider", 'Error');
        return;
      }
      business_data = {
        verify_business_name : verify_business_name,
        business_dba : business_dba,
        audit_location_physical_address : audit_location_physical_address,
        business_corporate_address : business_corporate_address,
        business_phone_number : business_phone_number,
        service_provider : service_provider
      }
    }else{
      business_data = {};
    }
    const data = { business_type: business_type, business_data: business_data };
    this.router.navigate(['site-visit-inspection/'+ this.site_visit_id], { state: { data } });
  }

  handleSuccess(captchaResponse: string): void {
    console.log('CAPTCHA Response:', captchaResponse);
    this.getSiteVisitDetails(captchaResponse);
    // Use the response token for server-side validation
  }

  handleError(error: any): void {
    console.error('CAPTCHA Error:', error);
  }

  handleExpired(): void {
    console.warn('CAPTCHA Expired');
    // Optionally reset the CAPTCHA if needed
  }

}
