import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-custom-accordion',
  template: `
  <div class="">
      <mat-accordion class="scroll-container">
      <ng-container *ngFor="let panel of this.field.fieldGroup; let i = index">
      <mat-expansion-panel 
          [expanded]="panel.templateOptions.expanded" 
           (opened)="onPanelOpened(i)"
          (closed)="onPanelClosed(i)">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span  class="status-indicator" class="checkmark">
              <ng-container [ngSwitch]="isSectionValid(i)">
                <mat-icon *ngSwitchCase="'valid'" class="valid">check_circle</mat-icon>
                <mat-icon *ngSwitchCase="'invalid'" class="invalid">cancel</mat-icon>
                <mat-icon *ngSwitchDefault class="default">radio_button_unchecked</mat-icon>
              </ng-container>
              </span>
              {{ panel.templateOptions.title }}
            
            </mat-panel-title>
          </mat-expansion-panel-header>

          <formly-field *ngFor="let f of panel.fieldGroup" [field]="f"></formly-field>
          <mat-action-row>
          <ng-container *ngIf="i === 0">
            <button mat-button color="primary" style="margin-left: auto !important;" (click)="nextAccordion(i)">Next</button>
          </ng-container>
          <ng-container *ngIf="i > 0">
            <button mat-button color="primary" (click)="previousAccordion(i)">Previous</button>
            <button mat-button color="primary" style="margin-left: auto;" *ngIf="i < field.fieldGroup.length - 1" (click)="nextAccordion(i)">Next</button>
          </ng-container>
        </mat-action-row>
          
          <!-- <mat-action-row>
            <button mat-button color="primary" *ngIf="i > 0" (click)="previousAccordion(i)">Previous</button>
            <button mat-button color="primary" *ngIf="i < field.fieldGroup.length - 1" (click)="nextAccordion(i)">Next</button>
          </mat-action-row> -->
        </mat-expansion-panel>
      </ng-container>
      </mat-accordion>
  </div>
  `,
  styles: [`
    mat-action-row {
      display: flex;
      justify-content: space-between;
    }
    .scroll-container {
  overflow-y: auto;
  scroll-behavior: smooth; /* For consistent smooth scrolling */
  max-height: 100vh; /* Limit the container height for better usability */
}

::ng-deep .mat-expansion-panel {
  margin-bottom: 10px !important; /* Ensure spacing between panels */
  scroll-margin-top: 16px; /* Adjust scrolling to ensure visibility */
}
    ::ng-deep .mat-expansion-panel-header-title{
        font-weight: 700 !important;
    }
    ::ng-deep .mat-action-row {
        padding: 16px 8px 16px 8px !important;
    }
    ::ng-deep .mat-action-row .mat-button-base, .mat-action-row .mat-mdc-button-base {
        margin-left: 0px !important;
    }
    ::ng-deep .mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
        color: #3f51b5 !important;
        font-weight: 600 !important;
    }
    .checkmark {
      color: green;
      font-weight: bold;
      margin-right: 6px;
      vertical-align: text-bottom;
    }
    .status-indicator {
        margin-left: 10px;
        font-size: 18px;
        vertical-align: middle;
      }
      .mat-icon.valid {
  color: green;
}
.mat-icon.invalid {
  color: red;
}
.mat-icon.default {
  color: lightgray;
    background: lightgray;
    border-radius: 50%;
    width: 20px;
    height: 20px;
}

  `],
})
export class FormlyAccordionTypeComponent extends FieldType  {


  ngOnInit() {
  }
  onPanelOpened(index: number) {
    this.field.fieldGroup.forEach((panel, i) => {
      panel.templateOptions.expanded = i === index;
    });
  }

  onPanelClosed(index: number) {
    this.field.fieldGroup[index].templateOptions.expanded = false;
  }

  // nextAccordion(index: number) {
  //   this.field.fieldGroup[index].templateOptions.expanded = false;
  //   this.field.fieldGroup[index + 1].templateOptions.expanded = true;
  // }

  // previousAccordion(index: number) {
  //   this.field.fieldGroup[index].templateOptions.expanded = false;
  //   this.field.fieldGroup[index - 1].templateOptions.expanded = true;
  // }

  nextAccordion(index: number) {
    if (index < this.field.fieldGroup.length - 1) {
      // Collapse the current panel
      this.field.fieldGroup[index].templateOptions.expanded = false;
  
      // Expand the next panel
      this.field.fieldGroup[index + 1].templateOptions.expanded = true;
  
      // Scroll to the top of the next panel

      this.scrollToPanel(index + 1, 200);
    }
  }
  
  previousAccordion(index: number) {
    if (index > 0) {
      // Collapse the current panel
      this.field.fieldGroup[index].templateOptions.expanded = false;
  
      // Expand the previous panel
      this.field.fieldGroup[index - 1].templateOptions.expanded = true;
  
      // Scroll to the top of the previous panel
      this.scrollToPanel(index - 1, 0);
    }
  }
  
  private scrollToPanel(index: number, height?) {
    setTimeout(() => {
      const panels = document.querySelectorAll('mat-expansion-panel');
      const container = document.querySelector('.scroll-container') as HTMLElement;
  
      if (panels[index] && container) {
        const panel = panels[index] as HTMLElement;
  
        // Adjust the scroll position to align with the top of the panel
        const scrollOffset = (panel.offsetTop - container.offsetTop) - height;
  
        container.scrollTo({
          top: scrollOffset,
          behavior: 'smooth', // Ensure smooth scrolling
        });

        const dropdownTrigger = panel.querySelector('mat-select') as HTMLElement;
        if (dropdownTrigger) {
          dropdownTrigger.focus();
            // dropdownTrigger.click();
        }
  
        // Focus the first input field in the panel for accessibility
        const firstInput = panel.querySelector('input, textarea, select') as HTMLElement;
        if (firstInput) {
          firstInput.focus();
        }
      }
    }, 200); // Adjust timeout to ensure the panel is fully expanded
  }
  
  isSectionValid(index: number): string {

    if(this.field.fieldGroup[index].templateOptions.expanded){
      const section = this.field.fieldGroup[index];
      const allFieldsEmpty = section.fieldGroup.every(field => {
        const control = field.formControl;
        return !control?.value || control.value === '';
      });
      const requiredFields = section.fieldGroup.filter(field => {
            const control = field.formControl;
           return control?.validator?.({} as any)?.required === true;
      });
      const allRequiredValid = requiredFields.every(field => field.formControl?.valid);
      if(allFieldsEmpty || !allRequiredValid){
        return 'incomplete'
      }
      else{
        return 'valid'
      }
    }
    if(!this.field.fieldGroup[index].templateOptions.expanded){
      const section = this.field.fieldGroup[index];
      const allFieldsEmpty = section.fieldGroup.every(field => {
        const control = field.formControl;
        return !control?.value || control.value === '';
      });
      const requiredFields = section.fieldGroup.filter(field => {
            const control = field.formControl;
           return control?.validator?.({} as any)?.required === true;
      });
      const allRequiredValid = requiredFields.every(field => field.formControl?.valid);
      if(allFieldsEmpty){
        return 'incomplete'
      }
      if(allRequiredValid){
        return 'valid'
      }else{
        return 'invalid'
      }
    }
  }
  
}
