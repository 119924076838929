<div class="container-fluid head_wrap">
    <div class="d-flex align-items-center ">
      <div class="logo">
        <div class="row align-items-center">
          <div class="col-auto">
            <!-- src="assets/images/logo.png" -->
            <a href="/">
              <img id="dynamicLogo" alt="" class="dynamic-logo logo-icon">
              <p id="providerWebURL"></p>
            </a>
          </div>
          <div class="col mt-3">
            <h1 id="dynamicHeader"></h1>
          </div>
        </div>
      </div>
     
    </div>
  </div>
<div *ngIf="isV2CaptchaRequired" 
    class="captcha-container mb-3">
    <ngx-recaptcha2
      [siteKey]="siteKey"
      (success)="handleSuccess($event)"
      (error)="handleError($event)"
      (expired)="handleExpired($event)">
    </ngx-recaptcha2>
  </div>
<section *ngIf="!isV2CaptchaRequired" class="mt-3">
    <div class="container-fluid p-0">
        <div class="card">
            <h3 class="card-header d-flex justify-content-between align-items-center">
                Site Visit Inspection
                <!-- <button class="float-end" mat-raised-button color="secondary" (click)="goBack()">Back</button> -->
            </h3>
            <div class="card-body">
                <div class="mx-6 mt-4">
                    <form [formGroup]="form">
                        <formly-form [form]="form" [fields]="fields" [model]="model" [options]="options"></formly-form>  
                    </form> 
                   
                    <!-- <div class="d-flex">
                        <div class="col-12 mb-3">
                            <button class="float-end blue_button mt-3" mat-raised-button type="submit" (click)="onSubmit(model)">Submit</button>
                        </div>
                    </div> -->

                    <!-- <div class="row mt-5 mb-3">
                        <div class="col-md-12 mt-1">
                            <div class="d-grid gap-2">
                                <button class="blue_button" mat-raised-button type="submit" (click)="onSubmit(model)">Submit</button>
                            </div>
                            
                        </div>
                    </div> -->
                </div>
                
            </div>
        </div>
    </div>
    <div class="float-end mt-3">
        <mat-label class="fs-6 fw-bold">V:{{version_no}}</mat-label>
    </div>

    
  
</section>